/* Header.module.css */

.appBar {
  background-color: transparent;
  box-shadow: none;
}

.topStripe {
  background-color: #e4252d; /* Red background */
  padding: 5px 5px;
}

.dateTime {
  font-size: 0.8em;
  color: #ffffff;
  text-align: left;
}

.toolbar {
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 64px; /* Adjust as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Constant light shadow at the bottom */
}

.menuButton {
  color: #000000; /* Red color for the menu button */
}

.logoContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo {
  height: 50px; /* Adjust as needed */
}

.drawer {
  width: 250px;
}

.closeButton {
  align-self: flex-end;
}


.drawer {
  width: 250px;
  position: relative;
}

.drawerCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.drawerLogoContainer {
  padding: 20px;
  text-align: center;
}

.drawerLogo {
  max-width: 100%;
  height: auto;
}